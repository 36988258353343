<template>
  <div wire:loading class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gradient-to-r from-slate-600 via-slate-700 to-slate-600 opacity-85 flex flex-col items-center justify-center">
    <!-- <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div> -->
    <div class="flex w-32 mb-6">
    <div class="bounceball" v-if="ballAmount > 0"></div>
    <div class="bounceball ml-3" v-if="ballAmount > 1"></div>
    <div class="bounceball ml-3" v-if="ballAmount > 2"></div>
    <div class="bounceball ml-3" v-if="ballAmount > 3"></div>
    <div class="bounceball ml-3" v-if="ballAmount > 4"></div>
    </div>
    <h2 class="text-center text-slate-200 text-sm font-normal">{{header}}</h2>
    <p class="w-1/3 text-center text-xs text-slate-200 opacity-60">{{subheader}}</p>
  </div>
</template>


<script>
export default {
  name: "Loader",
  props: ['header','subheader'],
  data(){
    return{
      ballAmount: 0
    }
  },
  created()
    {
      this.addBall()
    },
  methods:{
    addBall(){
      this.ballAmount++
      if(this.ballAmount <= 4){
        setTimeout(()=>{
          this.addBall()
        },150)
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .loader {
    border-top-color: rgb(138, 220, 255);;
    -webkit-animation: spinner 1.5s linear infinite;
    animation: spinner 1.5s linear infinite;
  }

  @-webkit-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

$width: 15px;
$height: 15px;
$bounce_height: 30px;

  .bounceball {
  position: relative;
  display: inline-block;
  height: 37px;
  width: $width;
  &:before {
    position: absolute;
    content: '';
    display: block;
    top: 0;
    width: $width;
    height: $height;
    border-radius: 50%;
    background-color: #64748b;
    transform-origin: 50%;
    animation: bounce 500ms alternate infinite ease;
  }
}

@keyframes bounce {
  0% {
    top: $bounce_height;
    height: 5px;
    border-radius: 60px 60px 20px 20px;
    transform: scaleX(2);
  }
  35% {
    height: $height;
    border-radius: 50%;
    transform: scaleX(1);
  }
  100% {
    top: 0;
  }
}
</style>
