<template>
  <div id="login" class="h-screen bg-slate-200">
    <transition name="fade">
      <Loader v-if="$store.state.globalLoader.show" :header="$store.state.globalLoader.head" :key="$store.state.globalLoader.head" :subheader="$store.state.globalLoader.subheader"></Loader>
    </transition>
    <div class="min-h-full flex">
      <div class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div class="mx-auto w-full max-w-sm lg:w-96">
          <transition name="fade">
            <div class="rounded-md bg-red-50 p-4 transition-all mb-6" v-show="error">
              <div class="flex">
                <div class="ml-3">
                  <h3 class="text-sm font-medium text-red-800">
                    Coś poszło nie tak w trakcie procesu logowania. Podejmij następujące akcje:
                  </h3>
                  <div class="mt-2 text-sm text-red-700">
                    <ul role="list" class="list-disc pl-5 space-y-1">
                      <li>
                        Sprawdź e-mail lub hasło.
                      </li>
                      <li>
                        Sprawdź połączenie z internetem. 
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </transition>
          <div>
            <h2 class="mt-6 text-3xl font-extrabold text-slate-900">
              Witaj w panelu klienta!
            </h2>
            <p class="mt-2 text-sm text-slate-600">
              WBtank Sp. z o.o.
            </p>
          </div>

          <div class="mt-6">
            <div>
              <div class="mt-6 relative">
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                  <div class="w-full border-t border-slate-400" />
                </div>
                <div class="relative flex justify-center text-sm">
                  <span class="px-2 bg-slate-200 text-slate-600">
                    Wpisz swój email oraz hasło, aby się zalogować.
                  </span>
                </div>
              </div>
            </div>

            <div class="mt-6">
              <form action="#" method="POST" class="space-y-6">
                <div>
                  <label for="email" class="block text-sm font-medium text-slate-700">
                    E-mail
                  </label>
                  <div class="mt-1">
                    <input id="email" placeholder="E-mail" name="email" type="email" v-model="email" autocomplete="email" required="" class="appearance-none block w-full px-3 py-2 border border-slate-300 rounded-md shadow-sm placeholder-slate-400 focus:outline-none focus:ring-blue-400 focus:border-blue-400 sm:text-sm transition-all" />
                  </div>
                </div>

                <div class="space-y-1">
                  <label for="password" class="block text-sm font-medium text-slate-700">
                    Hasło
                  </label>
                  <div class="mt-1">
                    <input id="password" placeholder="Hasło" name="password" v-model="password" type="password" autocomplete="current-password" required="" class="appearance-none block w-full px-3 py-2 border border-slate-300 rounded-md shadow-sm placeholder-slate-400 focus:outline-none focus:ring-blue-400 focus:border-blue-400 sm:text-sm transition-all" />
                  </div>
                </div>




                <div>
                  <button @click.prevent="logInUser" type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-400 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-300">
                    Zaloguj się
                  </button>
                </div>

                <div class="text-xs text-slate-700 font-medium">
                  &copy; Powered by <a href="https://complaia.systems/" class="text-purple-500 hover:text-purple-400">Complaia Systems</a>.
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="hidden lg:block relative w-0 flex-1">
        <img class="absolute inset-0 h-full w-full object-cover" src="https://firebasestorage.googleapis.com/v0/b/wbtank-65cca.appspot.com/o/assets%2Flogin_panel.jpeg?alt=media&token=ee1a4bc1-a20f-44d7-804b-8bbd78f6c511" alt="System wykonany przez Complaia Systems" />
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '../components/Loader.vue';
export default {
  name: "Login",
  components: {
    Loader
  },
   watch:{
    '$route'()
    {
      this.$store.commit("resetGlobalLoader");
    }
  },
  data: function () {
    return {
      error: false,
      email: "",
      password: ""
    }
  },
  methods: {
    async logInUser() {
      try {
        await this.$store.dispatch("login", {
          email: this.email,
          password: this.password,
        });
        this.error = false;
        this.$router.push(`/dashboard/transactions`);
      } catch (err) {
        this.error = true;
        console.log(err.message);
      }
    },
  },
};
</script>
<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
